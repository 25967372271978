import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { addQueryToUrl, removeFromUrl } from 'dpl/util/queryString';
import { preloadImage, isImageLoaded, difference } from 'dpl/shared/utils';
import withLazyLoading from 'dpl/decorators/withLazyLoading';

const IS_BOT_UA = window.__DPL_BOT_UA;

class SmartImage extends Component {
  static propTypes = {
    Tag: PropTypes.oneOf(['img', 'div']),
    className: PropTypes.string,
    src: PropTypes.string,
    minWidth: PropTypes.string,
    minHeight: PropTypes.string,
    width: PropTypes.string,
    height: PropTypes.string,
    alt: PropTypes.string,
    /**
     * className to be used while image is loading
     */
    loadingClass: PropTypes.string,
    /**
     * When `true`, add crop params to the dimensions given
     */
    crop: PropTypes.bool,
    /**
     * When `true`, use props.src as-is and not crop/resize
     */
    forceOriginalSrc: PropTypes.bool,
    wrapperOnlyClassName: PropTypes.string,
    imageOnlyClassName: PropTypes.string,
    onLoad: PropTypes.func,
    isInViewport: PropTypes.bool.isRequired,
    setLazyRef: PropTypes.func,
    allowContextMenu: PropTypes.bool
  };

  static defaultProps = {
    Tag: 'img',
    setLazyRef: null,
    className: '',
    src: null,
    loadingClass: '',
    minWidth: null,
    minHeight: null,
    width: null,
    height: null,
    alt: '',
    crop: false,
    forceOriginalSrc: false,
    wrapperOnlyClassName: '',
    imageOnlyClassName: '',
    onLoad: null,
    allowContextMenu: false
  };

  state = {
    isLoading: true
  };

  elementProps = {};
  dimensionStyles = {};
  devicePixelRatio = window.devicePixelRatio || 1;
  _willUnmount = false;

  shouldComponentUpdate(nextProps, nextState) {
    return (
      nextProps.src !== this.props.src ||
      this.props.isInViewport !== nextProps.isInViewport ||
      this.state.isLoading !== nextState.isLoading ||
      this.props.className !== nextProps.className
    );
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillUpdate(nextProps, nextState) {
    this.updateDimensionStyles(nextProps);
    this.updateImageSrcIfNeeded(nextProps, nextState);
    this.updateElementProps(nextProps);
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillMount() {
    this.updateDimensionStyles(this.props);
    this.updateImageSrcIfNeeded(this.props, this.state);
    this.updateElementProps(this.props);
  }

  componentWillUnmount() {
    this._willUnmount = true;
  }

  updateImageSrcIfNeeded(props) {
    if (!props.src || !props.isInViewport) {
      return false;
    }

    let newSrc = props.src;

    const isManuallyCropped = newSrc.includes('manual');

    if (
      !props.forceOriginalSrc &&
      this.dimensionStyles.width &&
      !/^(blob|data):/.test(props.src)
    ) {
      const width = Math.ceil(
        parseInt(this.dimensionStyles.width, 10) * this.devicePixelRatio
      );
      const height = Math.ceil(
        parseInt(this.dimensionStyles.height, 10) * this.devicePixelRatio
      );

      if (!isManuallyCropped) {
        // client crop/resize params take precedence over the server's
        newSrc = removeFromUrl(['resize', 'crop'], newSrc);
      }

      newSrc = addQueryToUrl(
        {
          // only support resizing of manually cropped images
          [props.crop && !isManuallyCropped ? 'crop' : 'resize']: `${width}x${
            height || width
          }`
        },
        newSrc
      );
    }

    if (newSrc === this.imageSrc) {
      return false;
    }

    this.imageSrc = newSrc;

    this.setState({
      isLoading: !isImageLoaded(this.imageSrc)
    });

    preloadImage(this.imageSrc)
      .catch(() => {
        /* still try to display image */

        /* eslint-disable-next-line no-console */
        console.error(`Failed to preload image ${this.imageSrc}`);
      })
      .then(target => {
        if (!this._willUnmount) {
          this.setState({ isLoading: false });
          props.onLoad && target && props.onLoad(target);
        }
      });

    return true;
  }

  updateDimensionStyles(props) {
    this.dimensionStyles = {
      width: props.width,
      height: props.height,
      minWidth: props.minWidth,
      minHeight: props.minHeight
    };
  }

  updateElementProps(props) {
    const { alt } = props;

    const tag = IS_BOT_UA ? 'img' : props.Tag;

    if (tag === 'img') {
      this.elementProps = {
        alt,
        src: this.imageSrc,
        style: {
          ...this.dimensionStyles,
          userDrag: 'none',
          WebkitUserDrag: 'none',
          userSelect: 'none'
        }
      };
    } else {
      // div
      this.elementProps = {
        role: 'img',
        title: alt,
        'aria-label': alt,
        style: {
          ...this.dimensionStyles,
          backgroundImage: this.imageSrc && `url('${this.imageSrc}')`
        }
      };
    }
  }

  handleContextMenu = e => {
    if (this.props.allowContextMenu) {
      return;
    }

    e.preventDefault();
  };

  render() {
    const { isLoading } = this.state;

    const {
      className,
      loadingClass,
      wrapperOnlyClassName,
      imageOnlyClassName,
      setLazyRef
    } = this.props;

    let outerClassName = className;
    if (imageOnlyClassName) {
      outerClassName = difference(
        className.split(' '),
        imageOnlyClassName.split(' ')
      ).join(' ');
    }

    const Tag = IS_BOT_UA ? 'img' : this.props.Tag;

    return (
      <div
        className={classnames(
          'SmartImage f0 dib',
          outerClassName,
          wrapperOnlyClassName,
          {
            [loadingClass]: isLoading
          }
        )}
        style={this.dimensionStyles}
        ref={setLazyRef}
      >
        {this.imageSrc && (
          <Tag
            {...this.elementProps}
            className={classnames('SmartImage__image', className, {
              'o-0': isLoading
            })}
            onContextMenu={this.handleContextMenu}
          />
        )}
      </div>
    );
  }
}

export default withLazyLoading()(SmartImage);
